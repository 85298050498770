import React, { useEffect, useRef } from 'react';
import World from "../components/World";
import { LoadWeb3, LoadCoordinate, LoadWallSignatures} from "../store/interactions";
import Spinner from "../components/Spinner";

const WorldMap = (props) => {
    const { coordinates, wall, dispatch } = props
    const shouldLoad = useRef(true)

    useEffect(() => {
        (async () => {
            if (shouldLoad.current) {
                shouldLoad.current = false
                    await LoadWeb3(props)
                    await LoadCoordinate(props)
                    await LoadWallSignatures(props)
            }
        })()
    }, [props, dispatch]);

    return (
        <>
            {coordinates && wall ? (
            
                    <World {...props} />
            
            ) : Spinner("Loading World Data")}
        </>
    );
}

export default WorldMap;

