import React from "react";
import styled from "styled-components";
import { colors } from "../utils/Colors";

const createRipple = (event) => {

    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter /2;
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
    circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`;
    circle.classList.add("ripple");
    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }
  
    button.appendChild(circle);
  };
  
  const ButtonFlat = ({
    action,
    icon = () => {
      return null;
    },
    message,
    waitForRipple = true,
    disabled = false,
    selected = false,
    type = "submit"
  }) => {
    const Icon = icon();
    return (
      <ButtonWrapper
        type={type}
        disabled={disabled}
        selected={selected}
        onClick={(e) => {
          createRipple(e);
          if (waitForRipple) {
            setTimeout(() => {
              action();
            }, 250);
          } else {
            action();
          }
        }}
      >
        {Icon} {message}
      </ButtonWrapper>
    );
  };
  
  export default ButtonFlat;
  
  const ButtonWrapper = styled.button`
    position: relative; /* Ensure position is set for stacking context */
    color: ${colors.theme.primary};
    background-color: ${(props) =>
      props.disabled ? '#cccccc' : colors.background.secondary};
    border: 2px solid ${(props) =>
      props.selected ? '#000000' : '#FFFFFF'};
    
    box-shadow: ${(props) =>
      props.selected ? '8px 8px 15px' : ""};

    border-radius: 25px;
    text-transform: uppercase;
    padding: 10px 10px;
    min-width: 100px;
    margin: 5px;
    // hover
    transition: box-shadow 0.15s ease-in-out;
    // ripple
    overflow: hidden;
    &:hover {
      box-shadow: 8px 8px 15px ${colors.background.primary};
    }
  
    span.ripple {
      position: absolute;
      top:0px;
      right:0px;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 400ms linear;
      background-color: ${colors.background.primary};
      border:1px solid #000000;
      z-index:3;
    }
  
    @keyframes ripple {
      to {
        transform: scale(40);
        opacity: 0.5;
      }
    }
  `;