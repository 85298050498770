import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    latitude: 0,
    longitude: 0,
}

export const coordinatesSlice = createSlice({
    name:"coordinates",
    initialState,
    reducers:{
        setCoordinates: (state,action) =>{
            const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = action.payload;
            state.accuracy = accuracy;
            state.altitude = altitude;
            state.altitudeAccuracy = altitudeAccuracy;
            state.heading = heading;
            state.latitude = latitude;
            state.longitude = longitude;
            state.speed = speed;
        }
    }
});

export const {setCoordinates} = coordinatesSlice.actions;

export default coordinatesSlice.reducer;