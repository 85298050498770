import DOMPurify from 'dompurify';
import { setCoordinates } from "./coordinates/coordinatesSlice";
import { setSignatures } from "./wall/signaturesSlice";
import { setWeb3 } from "./web3/web3Slice";
import { config } from "../utils/Config";
import { setError } from "../store/error/errorSlice";

const name = "idecentralize";
const version = "1";
const currentDBAPI = process.env.REACT_APP_DB_API

/////////////////////////////////////////////////////////////////
/// LOAD WEB3
export const LoadWeb3 = async (props) => {
    const { dispatch, publicClient } = props

    const chainId = await publicClient.getChainId()

    dispatch(setWeb3({
        account: null,
        ensName: null,
        ensAvatar: null,
        ensTwitter: null,
        ensGithub: null,
        chainId: chainId,
        ensEnabled: null,
    }))

}

/////////////////////////////////////////////////////////////////
/// LOAD GPS COORDINATE
export const LoadCoordinate = async (props) => {
    const { dispatch } = props
    if (navigator.geolocation) {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            dispatch(setCoordinates({
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                head: position.coords.head,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed,
            }
            ));
        } catch (error) {
            dispatchError(props, 'Error getting current position:', error);
        }
    } else {
        dispatchError(props, 'Geo Location not available in your browser', null);
    }

    return
}

/////////////////////////////////////////////////////////////////
/// LOAD WALL SIGNATURES
export const LoadWallSignatures = async (props) => {
    const { dispatch } = props
    try {
        const response = await fetch(currentDBAPI + "get-signatures"); // Replace with your API endpoint URL
        if (!response.ok) {
            throw new Error('Request failed');
        }
        const data = await response.json();
        dispatch(setSignatures({
            signatures: data,
        }));

    } catch (error) {
        console.log(error)
        dispatchError(props, 'Failed to fetch signatures', error);
        throw new Error(error);
    }
    return
}

/////////////////////////////////////////////////////////////////
/// VALIDATE SIGNATURE
export async function validateSignatureWithAPI(props, typedData, signature, account) {
    const data = {
        message: typedData.message.message,
        signature,
        from: account,
        lat: typedData.message.latitude,
        lng: typedData.message.longitude,
        tier: typedData.message.tier,
        time: typedData.message.time
    };

    let response
    try {
        response = await fetch(currentDBAPI + "insert-signature", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } catch (error) {
        dispatchError(props, "Failed to insert signature", error)
        throw new Error(error);
    }

    const responseData = await response.json();
    console.log('response',responseData)
    if (responseData.error) {
        dispatchError(props, "Failed to insert signature", responseData.error)
        throw new Error(responseData.error);
    }

    return responseData.signature
}

/////////////////////////////////////////////////////////////////
/// TYPED DATA CREATION
export function createTypedData(props) {
    const { coordinates, chainId, messageData, tier } = props

    const currentTime = new Date();
    const futureTime = new Date(currentTime.getTime() + 5 * 60000); // 60000 milliseconds in a minute
    const epochTime = Math.floor(futureTime.getTime() / 1000);
    const purifiedMessage = DOMPurify.sanitize(messageData);

    const typedData = {
        types: {
            Message: [
                { name: "message", type: "string" },
                { name: "latitude", type: "string" },
                { name: "longitude", type: "string" },
                { name: "tier", type: "uint256" },
                { name: "time", type: "uint256" },
            ],
        },
        primaryType: "Message",
        domain: {
            name: name,
            version: version,
            chainId: chainId,
            verifyingContract: config.app[chainId.toString()].www3contract,
        },
        message: {
            message: purifiedMessage,
            latitude: coordinates.latitude.toString(),
            longitude: coordinates.longitude.toString(),
            tier: tier,
            time: epochTime
        },
    };

    return typedData
}

/////////////////////////////////////////////////////////////////
/// DISPATCH ERROR
export function dispatchError(props, errorMsg, errorStack) {
    const { dispatch } = props

    if (errorStack.code === 4001) {
        dispatch(setError({
            errorMsg: "The signature request was rejected"
        }))
    } else {
        dispatch(setError({
            errorMsg: errorMsg
        }))
    }
    console.log(errorStack)

    return
}
