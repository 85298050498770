import React, { useState } from 'react';
import styled from 'styled-components';
import { setSettings } from '../store/settings/settingsSlice';
import { colors } from "../utils/Colors";

const SwitchButtonWrapper = styled.div`
  position: relative;
  margin:0px;
  width: 60px;
  height: 30px;
  background-color: ${props => (props.defaultChecked ? ' #180218' : '#815D81')};
  border-radius: ${props => (props.defaultChecked ? '0px' : '15px')};
  cursor: pointer;
  top: 0px;
  border: 1px solid ${colors.background.secondary};
  z-index: 99;
  transition: border-radius 0.3s;
`;

const Slider = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 28px;
  height: 28px;
  border-radius: ${props => (props.defaultChecked ? '0px' : '15px')};
  background-color: ${colors.background.secondary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transform: ${props => (props.defaultChecked ? 'translateX(30px)' : 'translateX(0)')};
  transition: transform 0.3s;
`;

const SwitchButton = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const {dispatch} = props

    const handleToggle = () => {
        setIsChecked(!isChecked);
        let projection
        if(props.mapProjection === 'globe'){
         projection = 'mercator'
        } else {
        projection = 'globe'
        }
        dispatch(
          setSettings(
            {globeMode: projection}
          )
        )
    };

    return (
        <SwitchButtonWrapper defaultChecked={isChecked} onClick={handleToggle}>
            <Slider defaultChecked={isChecked} />
        </SwitchButtonWrapper>
    );
};

export default SwitchButton;