import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    tier: 2
}

export const tierSlice = createSlice({
    name:"tier",
    initialState,
    reducers:{
        setTier: (state,action) =>{
            const { tier } = action.payload;
            state.tier= tier ;
        },
        unsetTier: (state) =>{
            state.tier = 2;
        },
    }
});

export const {setTier, unsetTier} = tierSlice.actions;

export default tierSlice.reducer;