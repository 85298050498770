import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    signatures: [],
}

export const signaturesSlice = createSlice({
    name:"signatures",
    initialState,
    reducers:{
        setSignatures: (state,action) =>{
            const { signatures } = action.payload;
            state.signatures = signatures;
            state.loaded = true;
            
        },
        addSignature: (state, action) => {
            const newSignature = action.payload;
            state.signatures.push(newSignature);
            state.loaded = true;
          },
    }
});

export const {setSignatures, addSignature} = signaturesSlice.actions;

export default signaturesSlice.reducer;