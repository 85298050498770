import React from 'react';
import styled from 'styled-components';
import ButtonsOutline from "./ButtonFlat";
import { createTypedData, validateSignatureWithAPI, dispatchError } from "../store/interactions";
import { setMessage } from "../store/message/messageSlice";
import { setTier } from "../store/tier/tierSlice";
import XButton from "./XButton";
import { useWalletClient, useContractWrite, useAccount, useWaitForTransaction } from 'wagmi'
import { config } from "../utils/Config";
import www3Contract from '../ABI/WorldWideWeb3.json'
import { parseEther } from 'viem';
import { Container } from 'react-bootstrap';
//import axios from 'axios';

const WallMsgForm = (props) => {

    const { dispatch, messageLoaded, messageData, messageSignature, chainId } = props

    const { isConnecting, isConnected } = useAccount()

    // get the wallet
    const { data: walletClient } = useWalletClient()

    // write to contract
    let { data: transaction, write } = useContractWrite({
        address: config.app[chainId.toString()].www3contract,
        abi: www3Contract.abi,
        functionName: 'submitMessage',
    })

    //once the transaction have been signed we can listen for it

    const receipt = useWaitForTransaction({
        hash: transaction?.hash,
    })

    if (receipt.isSuccess) {
        console.log(receipt.data)
    }


    const handleTwitterConnect = async () => {

        // function stringToBase64(input) {
        //     const encoder = new TextEncoder();
        //     const data = encoder.encode(input);
        //     const base64 = btoa(String.fromCharCode.apply(null, data));
        //     return base64;
        //   }


        // // Replace these with your Twitter API credentials
        // const apiKey = process.env.REACT_APP_TWITTER_API_KEY;
        // const apiSecret = process.env.REACT_APP_TWITTER_API_SECRET;
        //   const credentials = `${apiKey}:${apiSecret}`;
        //   const base64Credentials = stringToBase64(credentials);
        //   const authorizationHeader = `Basic ${base64Credentials}`;
        // try {


        //   // Step 1: Get a request token
        //   const response = await axios.post('https://api.twitter.com/oauth/request_token', null, {
        //     headers: {
        //         Authorization: authorizationHeader,
        //     },
        //   });

        //   // Step 2: Redirect the user to the Twitter authorization URL
        //   const requestToken = new URLSearchParams(response.data).get('oauth_token');
        //   window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${requestToken}`;
        // } catch (error) {
        //   console.error('Twitter connection error', error);
        // }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const address = await walletClient.requestAddresses()
        // retrieve typedData
        const typedData = createTypedData(props)

        let signature
        try {
            signature = await walletClient.signTypedData(typedData)
        } catch (error) {
            dispatchError(props, "Failed to sign message", error)
            return
        }

        let validatorSignature
        try {
            validatorSignature = await validateSignatureWithAPI(props, typedData, signature, address[0])
        } catch (error) {
            dispatchError(props, "Failed to validate signature" + error, error)
            return
        }

        // eval selected tier
        console.log("selected tier:", props.tier)

        try {
            write({
                args: [
                    typedData.message.message,
                    typedData.message.latitude,
                    typedData.message.longitude,
                    typedData.message.time,
                    typedData.message.tier,
                    signature,
                    validatorSignature
                ],
                from: address[0],
                value: parseEther('0.003'),
            })
        } catch(error) {
            dispatchError(props, "Failed to submit transaction", error)
            return
        }

    };


    const handleMsgChange = async (event) => {
        event.preventDefault();
        const message = event.target.value
        dispatch(setMessage({
            message
        }))

    };


    const handleStandardTier = async (event) => {

        dispatch(setTier({tier:2}))
    };
    const handlePremiumTier = async (event) => {

        dispatch(setTier({tier:3}))
    };

    const Tier1Button = () => {
        return (
            <ButtonsOutline
                action={() => {
                    handleTwitterConnect()
                }}
                disabled={true}
                message="Free"
                waitForRipple={true}
                type="button"
                selected={props.tier === 1}
            />
        )
    }

    const Tier2Button = () => {
        return (
            <ButtonsOutline
                action={() => {
                    handleStandardTier()
                }}
                message="Tier 2"
                waitForRipple={true}
                type="button"
                selected={props.tier === 2}
            />
        )
    }

    const Tier3Button = () => {
        return (
            <ButtonsOutline
                action={() => {
                    handlePremiumTier()
                }}
                message="Tier 3"
                waitForRipple={true}
                type="button"
                selected={props.tier === 3}
            />
        )
    }

    const ShowConnectMsg = () => {
        return (
            <ConnectMsgWrapper>
                In order for you to post a message on the World Wide Web3, you must connect your wallet by using the connect button at the top right of the screen.
            </ConnectMsgWrapper>
        )
    }

    const ShowConnecingtMsg = () => {
        return (
            <ConnectMsgWrapper>
                Waiting on the connection to finalize
            </ConnectMsgWrapper>
        )
    }

    const ShowForm = (props) => {
        return (
            <FormContainer onSubmit={handleSubmit}>
                <InputField type="text" name="message" placeholder="Enter your message to the world" onChange={handleMsgChange} />
                <TierSelector>
                    <TierWrapper>
                        <Tier>
                            <Tier1Button {...props} onClick={handleTwitterConnect} />
                        </Tier>
                    </TierWrapper>
                    <TierWrapper>
                        <Tier>
                            <Tier2Button {...props} onClick={handleStandardTier} />
                        </Tier>
                    </TierWrapper>
                    <TierWrapper>
                        <Tier>
                            <Tier3Button {...props} onClick={handlePremiumTier} />
                        </Tier>
                    </TierWrapper>
                </TierSelector>

                <>
                    {messageLoaded && messageSignature !== "" ? <XButton text={messageData + "\n\n I have just validated my ethereum account on X with the WWW3 app by signing this message. \n signature: " + messageSignature} /> : ""}
                </>

                {SignButton()}
            </FormContainer>
        )

    }

    return (
        <Wrapper>
            {isConnected ? ShowForm() : isConnecting ? ShowConnecingtMsg() : ShowConnectMsg()}
        </Wrapper>
    );
}

export default WallMsgForm;

const SignButton = () => {
    return (
        <ButtonsOutline
            action={() => {

            }}
            message="Sign the wall"
            waitForRipple={true}

        />
    )
}

const ConnectMsgWrapper = styled(Container)`
display:flex;
height:200px;
width: 80%;
font-weight:bold;
align-items: center;
border-radius:10px;
background-color:rgba(255,255,255,0.8);
justify-content: center;
z-index-2;
color: #000000;
`;

const TierSelector = styled.div`
display: flex;
justify-content: center;
align-items: flex-start; /* Align tiers at the top */
flex-direction: row; /* Display tiers in a row */
`;

const Wrapper = styled.div`
  text-align:center;
  width: 100vw;
`;

const FormContainer = styled.form`
  position:relative;
  width: 60%;
  padding:0px;
  text-align:center;
  margin:auto;
  background: var(--wui-gray-glass-005);
  border-radius:10px;

  @media (max-width: 768px) {
    width: 100%; /* Set the width to 100% on screens smaller than 768px */
    border-radius:0px;
  }
`;

const InputField = styled.textarea`
  position:relative;
  width: 80%;
  height: 125px;
  padding: 10px;
  margin:0px !important;
  border: 1px solid #ccc;
  border-radius: 25px;
  color:#000000;
  @media (max-width: 768px) {
    width: 100%; /* Set the width to 100% on screens smaller than 768px */
  
  }
`;


const TierWrapper = styled.div`
display: flex;
flex-direction: column;
margin:0px;
align-items: center;
cursor: pointer;
border-radius:5px;
`;

const Tier = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0px;
  cursor: pointer;
  align-self: flex-end; /* Align the tier from the bottom */
`;
