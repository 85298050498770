import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    message: [],
}

export const warningSlice = createSlice({
    name:"warning",
    initialState,
    reducers:{
        setWarning: (state,action) =>{
            const { message } = action.payload;
            state.message = message;
            state.loaded = true;
        },
        unsetWarning: (state) =>{
            state.message = [];
            state.loaded = false;
        },
    }
});

export const {setWarning, unsetWarning} = warningSlice.actions;

export default warningSlice.reducer;