const config = {

    org: {
        name:"madeindreams.ca",
        url:"https://app.madeindreams.ca",
    },

    app:{
        "31337":{
            www3contract: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
            www3shares:"0x5FbDB2315678afecb367f032d93F642f64180aa3",
        },
        "5":{
            www3contract:"0x4857bffC70EF93a2f828C621c8F5484B961BbEe8",
            www3shares:"0xffe6c51a55CBb7BC735BcdF39690A5cdb176D668",
        },
        "137":{
            www3contract:"0xBB6F3B60bfA4Cb298450173A7E2C3d7adeFBcE1C",
            www3shares:"0x2DD095cC1fEf8451d84DC70abDE25E889F980907",
        }


    },



}

export {
    config,
  }
