import React from "react";
import ButtonsOutline from "./ButtonFlat";

const XButton = ({ text }) => {
    const handleTweet = () => {
        // Create a URL with the tweet content

        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;

        // Open a new window with the Twitter Web Intent
        window.open(tweetUrl, "_blank", "width=550,height=420");
    };

    return (
        <ButtonsOutline
            action={() => {
                handleTweet()
            }}
            message="X about it"
            waitForRipple={true}
        />
    );
};

export default XButton;