import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Container} from "react-bootstrap";

// Define the spinning animation
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the spinning logo
const SpinningLogo = styled.img`
  animation: ${spinAnimation} 3s linear infinite;
  height:50px;
`;

// Styled component for the loading text
const LoadingText = styled.p`
  margin-top: 10px;
  text-align: center;
  color:#000000;
`;

const SpinnerContainer = styled(Container)`
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top:60px;
    margin:auto;
  text-align: center;
  height: 100vh;
  flex-direction: column;
`;


// React component that uses the spinning logo
const Spinner = (message) => {
    return (
        <SpinnerContainer>
            <SpinningLogo src="./logos/logo.svg" alt="Logo" />
            <LoadingText>{message}</LoadingText>
        </SpinnerContainer>
    )
};

export default Spinner;