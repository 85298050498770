import { configureStore } from '@reduxjs/toolkit';
import coordinatesReducer from './coordinates/coordinatesSlice';
import signaturesReducer from './wall/signaturesSlice';
import warningReducer from './warning/warningSlice';
import web3Reducer from './web3/web3Slice';
import messageReducer from './message/messageSlice';
import contractReducer from './contract/contractSlice';
import settingsReducer from './settings/settingsSlice';
import tierReducer from './tier/tierSlice';
import errorReducer from './error/errorSlice';

export const store = configureStore({
       reducer: {
                coordinates: coordinatesReducer,
                signatures: signaturesReducer,
                warning: warningReducer,
                web3: web3Reducer,
                message: messageReducer,
                contract: contractReducer,
                settings: settingsReducer,
                tier: tierReducer,
                error: errorReducer,
        }
});
