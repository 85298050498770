import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globeMode: 'mercator'
}

export const settingsSlice = createSlice({
    name:"settings",
    initialState,
    reducers:{
        setSettings: (state,action) =>{
            const { globeMode } = action.payload;
            state.globeMode = globeMode ;
        },
        unsetSettings: (state) =>{
            state.globeMode = false;
        },
    }
});

export const {setSettings, unsetSettings} = settingsSlice.actions;

export default settingsSlice.reducer;