import React, { Component } from 'react'
import styled from "styled-components";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import "./world.css"
import WallMsgForm from "../components/WallMsgForm"
import { BsChevronDoubleUp, BsChevronDoubleDown } from 'react-icons/bs'
import { colors } from "../utils/Colors";
import SwitchButton from './SwitchButton';

class World extends Component {

    constructor(props) {
        super(props)
        this.state = {
            latitude: this.props.coordinates.latitude,
            longitude: this.props.coordinates.longitude,
            zoom: 3,
            panelIsVisible: false,
            globeMode: this.props.mapProjection,
            spinEnabled: false,
        };
        this.map = React.createRef();
        this.mapContainer = React.createRef()
        this.wall = this.props.wallLoaded
        this.element = null
        this.showPanel = this.showPanel.bind(this);
        this.hidePanel = this.hidePanel.bind(this);
        this.secondsPerRevolution = 120;
        // Above zoom level 5, do not rotate.
        this.maxSpinZoom = 5;
        // Rotate at intermediate speeds between zoom levels 3 and 5.
        this.slowSpinZoom = 3;
        this.userInteracting = false;
        this.shouldStartSpinning = false;
    }

    // SPIN GLOBE METHOD
    spinGlobe = () => {
        if (this.state.spinEnabled && !this.userInteracting && this.state.zoom < this.maxSpinZoom) {
            let distancePerSecond = 360 / this.secondsPerRevolution;
            if (this.state.zoom > this.slowSpinZoom) {
                // Slow spinning at higher zooms
                const zoomDif =
                    (this.maxSpinZoom - this.state.zoom) / (this.maxSpinZoom - this.slowSpinZoom);
                distancePerSecond *= zoomDif;
            }
            const center = this.map.current.getCenter();
            center.lng -= distancePerSecond / 60;
            // Smoothly animate the map over one second.
            // When this animation is complete, it calls a 'moveend' event.
            this.map.current.easeTo({ center, duration: 16, easing: (n) => n });
        };
    };

     //set coordinates
     setLat(lat) {
        this.setState({ latitude: lat });
    }
    setLng(lng) {
        this.setState({ longitude: lng });
    }

    // Message panel
    showPanel() {
        this.setState({ panelIsVisible: true })
    }
    hidePanel() {
        this.setState({ panelIsVisible: false })
    }

    // COMPONENT DID UPDATE
    componentDidUpdate(prevProps) {
        // we set the map projection when it changes
        if (this.props.mapProjection !== prevProps.mapProjection) {
            if (this.props.mapProjection === 'globe') {
                this.setState({
                    spinEnabled: true
                },
                    () => {
                        this.spinGlobe();
                    });
                this.shouldStartSpinning = true;
            } else {
                this.setState({
                    spinEnabled: false
                });
            }
            this.map.current.setProjection(this.props.mapProjection);
        }
    }


    componentDidMount() {

        // styles
        // original  'mapbox://styles/idecentralize/cl29mboen000g14nswjdiryca'

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY
        this.map.current = new mapboxgl.Map({
            container: this.mapContainer.current,
            projection: this.state.globeMode,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [this.state.longitude, this.state.latitude],
            zoom: this.state.zoom,
            pitch: 0,
            bearing: 0,
            renderWorldCopies: false, // Add this line to disable alpha-premultiply and y-flip warnings
            preserveDrawingBuffer: true, // Add this line to disable alpha-premultiply and y-flip warnings
        });


        // add zoom control
        // this.map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');


        // create the geojson object
        const geojson = {
            type: "FeatureCollection",
            features: [
                {
                    type: 'Feature',
                    properties: {
                        iconSize: [35, 35],
                        name: "MadeInDreams.ca",
                        message: 'This is where this app was created',
                        avatar: "https://madeindreams.ca/logo.png",
                        github: "accountName",
                        twitter: "accountName"
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: ['-117.276954', '49.503071'],
                    },
                }
            ],
        };

        //create the markers
        this.wall.forEach((entry) => {
            //set the size of the icon based on tier
            let size
            switch (entry.tier) {
                case null:
                    size = 25;
                    break;
                case 1:
                    size = 25;
                    break;
                case 2:
                    size = 35;
                    break;
                case 3:
                    size = 45;
                    break;
                default:
                    size = 25;
            }


            const marker = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [entry.lng, entry.lat],
                },
                properties: {
                    message: entry.message,
                    iconSize: [size, size],
                    name: entry.ensname,
                    avatar: entry.ensavatar,
                    description: entry.message
                },
            };

            geojson.features.push(marker);
        });




        // load the map style
        this.map.current.on('style.load', () => {

            // atmosphere
            this.map.current.setFog({
                color: 'rgb(186, 39, 127)', // Lower atmosphere
                'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
                'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
                'space-color': 'rgb(11, 11, 25)', // Background color
                'star-intensity': 0.6 // Background star brightness (default 0.35 at low zoooms )
            });

            // terrain
            this.map.current.addSource('mapbox-dem', {
                type: 'raster-dem',
                url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                tileSize: 256,
                maxzoom: 21
            });

            // add the DEM source as a terrain layer with exaggerated height
            this.map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.3 });
        });






        //Mobile device touch control
        this.map.current.on('touchstart', () => {
            this.userInteracting = true;
        });
        this.map.current.on('touchend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });

        // Desktop click control
        // Pause spinning on interaction
        this.map.current.on('mousedown', () => {
            this.userInteracting = true;
        });
        // Restart spinning the globe when interaction is complete
        this.map.current.on('mouseup', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        // These events account for cases where the mouse has moved
        // off the map, so 'mouseup' will not be fired.
        this.map.current.on('dragend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.current.on('pitchend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.current.on('rotateend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        // When animation is complete, start spinning if there is no ongoing interaction
        this.map.current.on('moveend', () => {
            this.spinGlobe();
        });






        // update lat and lng on move
        if (!this.map.current) return;
        this.map.current.on('move', () => {
            const { lng, lat } = this.map.current.getCenter();
            this.setState({
                longitude: lng,
                latitude: lat,
                zoom: this.map.current.getZoom().toFixed(2),
            });
        });



        // marker
        geojson.features.forEach((marker) => {
            // Create a DOM element for each marker
            const markerWrapper = document.createElement('div');
            const el = document.createElement('div');
            const width = marker.properties.iconSize[0];
            const height = marker.properties.iconSize[1];
            el.className = 'marker';
            el.style.backgroundImage = `url(${marker.properties.avatar})`;
            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
            el.style.borderRadius = '50%';
            el.style.backgroundSize = '100%';

            // Add hover event listeners to the marker element
            el.addEventListener('mouseenter', () => {
                el.classList.add('crosshair');
            });
            el.addEventListener('mouseleave', () => {
                el.classList.remove('crosshair');
            });

            markerWrapper.appendChild(el);

            // the popup element
            const popupContent = document.createElement('div');
            popupContent.className = 'custom-popup';
            popupContent.innerHTML = `
                <h3>${marker.properties.name}</h3>
                <p>${marker.properties.message}</p>
            `;


            // create a popup for each marker
            const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContent);

            // Add a click event listener to each marker
            el.addEventListener('click', () => {
                popup.addTo(this.map.current);

            });

            // Add markers to the map
            new mapboxgl.Marker(markerWrapper)
                .setLngLat(marker.geometry.coordinates)
                .setPopup(popup)
                .addTo(this.map.current)
        });

    }

    render() {
        return (
            <MainWrapper>
                <SideBar className="sidebar">
                    Long: {this.state.longitude.toFixed(3)} | Lat: {this.state.latitude.toFixed(3)} | Zoom: {this.state.zoom}
                </SideBar>
                <AccountContainer>
                    <w3m-button />
                </AccountContainer>
                <SwitchContainer> <SwitchButton {...this.props} /></SwitchContainer>
                <HiddenContainer visible={this.state.panelIsVisible.toString()}>
                    <Arrow >
                        {this.state.panelIsVisible ? <BsChevronDoubleDown onClick={this.hidePanel} /> : <BsChevronDoubleUp onClick={this.showPanel} />}
                    </Arrow >
                    <WallMsgForm {...this.props} />
                </HiddenContainer>
                <MapContainer ref={this.mapContainer}></MapContainer>
            </MainWrapper>
        )
    }
}

export default World;

const Arrow = styled.h4`
margin:auto;
width:30px;
font-weight:bold;
color:#ffffff;
background-color:${colors.background.secondary};
border-radius:50%;
`;

const MainWrapper = styled.div`
position:absolute;
overflow-y:hidden;
height:100%;
width:100%;
`;

const AccountContainer = styled.div`
position:absolute;
top:0px;
right:0px;
width: auto;
margin:10px;
font-size:20px;
z-index:2;
`;

const SwitchContainer = styled.div`
position:absolute;
top:60px;
right:0px;
width: auto;
margin:10px;
z-index:2;
`;

const MapContainer = styled.div`
position:absolute;
top:0px;
width:100%;
height:100%;
color:#ffffff;
overflow-y:hidden;
z-index:1;
`;
const SideBar = styled.div`
color: #fff;
font-family: monospace;
position: absolute;
top: 0px;
left: 0;
font-size:10px;
z-index:2;
`;

const HiddenContainer = styled.div`
  position: fixed;
  bottom: ${({ visible }) => (visible === 'true' ? '0' : '-245px')};
  left: 0;
  right: 0;
  text-align:center;
  height: 275px; /* Adjust this based on your content */
  transition: bottom 0.3s ease-in-out;
  z-index: 2;
`;