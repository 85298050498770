import React from 'react';
import styled from 'styled-components';
import ButtonsOutline from "./ButtonFlat";
import { unsetError } from '../store/error/errorSlice';

// Styled component for the loading text

const ErrorBox = styled.div`
  position:relative;
  text-align: center;
  background-color:#000000;
  padding:10px;
  border-radius:10px;
`;

const ErrorMsg = styled.p`
  margin-top: 10px;
  font-weight:bold;
  text-align: center;
  color:#ffffff;
`;

const ErrorContainer = styled.div`
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  text-align: center;
  height: 100vh;
  width:100vw;
  flex-direction: column;
  z-index:3;
  background-color:rgba(255,255,255,0.3);
`;

const OkButton = (props) => {
  const {dispatch} = props
  return (
      <ButtonsOutline
          action={() => {
              dispatch(unsetError())
          }}
          message="OK"
          waitForRipple={true}
          type="button"
      />
  )
}

// React component that uses the spinning logo
const Error = (props) => {
  const {error} = props
    return (
        <ErrorContainer>
          <ErrorBox >
            <ErrorMsg>{error.errorMsg}</ErrorMsg>
            <OkButton {...props}/>
            </ErrorBox >
        </ErrorContainer>
    )
};

export default Error;
