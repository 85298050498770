import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    message: [],
    signature: ""
}

export const messageSlice = createSlice({
    name:"message",
    initialState,
    reducers:{
        setMessage: (state,action) =>{
            const { message, signature } = action.payload;
            state.message = message;
            state.signature = signature
            state.loaded = true;
        },
        unsetSignature: (state) =>{
            state.message = [];
            state.signature = ""
            state.loaded = false;
        },
    }
});

export const {setMessage, unsetMessage} = messageSlice.actions;

export default messageSlice.reducer;