import { createSelector } from 'reselect'
import { get } from 'lodash'



const web3 = state => get(state, 'web3.loaded', false)
export const web3Selector = createSelector(web3, w => w)
const web3Signer = state => get(state, 'web3.account', [])
export const web3SignerSelector = createSelector(web3Signer, wd => wd)

const contract = state => get(state, 'contract.loaded', false)
export const contractLoadedSelector = createSelector(contract, w => w)
const contractData = state => get(state, 'contract.contract', [])
export const contractDataSelector = createSelector(contractData, wd => wd)

const chainId = state => get(state, 'web3.chainId', [])
export const chainIdSelector = createSelector(chainId, wd => wd)


const coord = state => get(state, 'coordinates', [])
export const coordSelector = createSelector(coord, c => c)

const wall = state => get(state, 'signatures.signatures', [])
export const wallLoadedSelector = createSelector(wall, wl => wl)
const wallLoaded = state => get(state, 'signatures.loaded', false)
export const wallSelector = createSelector(wallLoaded, wl => wl)

const messageLoaded = state => get(state, 'message.loaded', false)
export const messageLoadedSelector = createSelector(messageLoaded, ml => ml)
const messageData = state => get(state, 'message.message', [])
export const messageDataSelector = createSelector(messageData, md => md)
const messageSignature = state => get(state, 'message.signature', "")
export const messageSignatureSelector = createSelector(messageSignature, ms => ms)

const mapProjection = state => get(state, 'settings.globeMode', false)
export const mapProjectionSelector = createSelector(mapProjection, ms => ms)

const tier = state => get(state, 'tier.tier', 2)
export const tierSelector = createSelector(tier, t => t)


const error = state => get(state, 'error', {isError:false, errorMsg:[], errorCode:0})
export const errorSelector = createSelector(error, e => e)