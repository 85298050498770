import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    account: [],
    ensName: [],
    ensAvatar: [],
    ensTwitter: [],
    ensGithub:[],
    chainId:[],
    ensEnabled: false,
}

export const web3Slice = createSlice({
    name:"web3",
    initialState,
    reducers:{
        setWeb3: (state,action) =>{
            const { account, ensName, ensAvatar, ensTwitter, ensGithub, chainId , ensEnabled} = action.payload;
            state.account = account;
            state.ensName = ensName;
            state.ensAvatar = ensAvatar;
            state.ensTwitter = ensTwitter;
            state.ensGithub = ensGithub;
            state.chainId = chainId;
            state.loaded = true;
            state.ensEnabled = ensEnabled;
        },
        unsetWeb3: (state) =>{
            state.account = [];
            state.ensName = [];
            state.ensAvatar = [];
            state.ensTwitter = [];
            state.ensGithub = [];
            state.chainId = [];
            state.loaded = false;
            state.ensEnabled = false;
        },
    }
});

export const {setWeb3, unsetWeb3} = web3Slice.actions;

export default web3Slice.reducer;