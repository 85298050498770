import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import styled from "styled-components";
import { colors } from "./utils/Colors";
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import { goerli, mainnet, polygon} from 'wagmi/chains'
import { usePublicClient } from 'wagmi'
import {
    chainIdSelector,
    coordSelector,
    wallLoadedSelector,
    wallSelector,
    web3Selector,
    messageLoadedSelector,
    messageDataSelector,
    messageSignatureSelector,
    mapProjectionSelector,
    tierSelector,
    errorSelector

} from "./store/selectors";
import WorldMap from "./pages/WorldMap";
import Error from './components/Error';


const projectId = process.env.REACT_APP_WM_PROJECT_ID

const metadata = {
    name: 'WWW3',
    description: 'World Wide Web3',
    url: 'https://app.madeindreams.ca',
    icons: ['https://madeindreams.ca/logo.png'],
}

const chains = [goerli, mainnet, polygon]
const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
     })

createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    themeVariables: {
        '--w3m-accent': '#ba277f'
      }
     })


function App() {
    const web3Loaded = useSelector(web3Selector)
    const chainId = useSelector(chainIdSelector)
    const wallLoaded = useSelector(wallLoadedSelector)
    const wall = useSelector(wallSelector)
    const coordinates = useSelector(coordSelector)
    const messageLoaded = useSelector(messageLoadedSelector)
    const messageData = useSelector(messageDataSelector)
    const messageSignature = useSelector(messageSignatureSelector)
    const mapProjection = useSelector(mapProjectionSelector)
    const tier = useSelector(tierSelector)
    const error = useSelector(errorSelector)
    const dispatch = useDispatch();

    const publicClient = usePublicClient()

    const props = {
        dispatch,
        coordinates,
        wallLoaded,
        wall,
        web3Loaded,
        chainId,
        messageLoaded,
        messageData,
        messageSignature,
        publicClient,
        mapProjection,
        tier,
        error
    }

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <DappWrapper>
                    {error.isError ? <Error{...props}/>: ""}
                    < WorldMap {...props} />
                </DappWrapper>
            </WagmiConfig>
        </>
    )
}

export default App;

const DappWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  padding: 0;
  background: ${colors.background.primary};
  
`;
