import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isError: false,
    errorMsg: [],
    errorCode: 0
}

export const errorSlice = createSlice({
    name:"error",
    initialState,
    reducers:{
        setError: (state,action) =>{
            const { errorMsg, errorCode } = action.payload;
            state.isError = true;
            state.errorMsg = errorMsg;
            state.errorCode = errorCode;
        },
        unsetError: (state) =>{
            state.isError = false;
            state.errorMsg = [];
            state.errorCode = 0;
        },
    }
});

export const {setError, unsetError} = errorSlice.actions;

export default errorSlice.reducer;