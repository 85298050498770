import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    contract: [],
}

export const contractSlice = createSlice({
    name:"contract",
    initialState,
    reducers:{
        setContract: (state,action) =>{
            const { contract } = action.payload;
            state.loaded = true;
            state.contract = contract;
        },
        unsetContract: (state) =>{
            state.loaded = false;
            state.contract = [];
        },
    }
});

export const {setContract, unsetContract} = contractSlice.actions;

export default contractSlice.reducer;