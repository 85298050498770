const colors = {

    background: {
        primary: "#FFFFFF",
        secondary: "#ba277f",
        translucid: "rgb(103,89,102,0.2)",
        translucid2: "rgb(18,20,20,0.9)"
    },
    text:{
        primary: "#000000",
        navigator: "#ffffff",
        footer: "#6b6969"
    },
    foreground:{
        primary: "#000000"
    },
    borders:{
        primary: "#000000"
    },
    shadows:{
        primary: "#000000"
    },
    theme:{
        primary:"#FFFFFF"
    },
    black: "#000000"
}

export {
    colors,
}